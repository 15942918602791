
import { defineComponent, computed, ref } from 'vue'
import MasterTable from '@/components/master/MasterTable.vue'
import {
  required as requiredRule,
  booleanTrueRequired,
} from '@/common/formValidationRules'
import { v4 as uuidv4 } from 'uuid'
import { useVehicleWithdrawalDepot } from '@/composable/useVehicleWithdrawalDepot'
import { VehicleWithdrawalDepot } from '@/types/vehicle-withdrawal-depot'

export default defineComponent({
  name: 'VehicleWithdrawalDepots',

  components: {
    MasterTable,
  },

  setup() {
    const {
      loading: loadingStateDepots,
      data: depots,
      add,
      update,
      remove,
      fetchAll,
    } = useVehicleWithdrawalDepot()

    fetchAll()

    function createFormModel(): Partial<VehicleWithdrawalDepot> {
      return {
        uuid: uuidv4(),
        name: '',
      }
    }
    const model = ref<Partial<VehicleWithdrawalDepot>>(createFormModel())

    function resetFormModel() {
      model.value = createFormModel()
    }

    const columns = [
      {
        name: 'name',
        label: 'Depå',
        align: 'left',
        field: 'name',
        sortable: true,
      },
    ]

    const loading = computed(() => {
      return {
        ...loadingStateDepots.value,
      }
    })

    const exportData = computed(() => {
      return depots.value.map((depot) => {
        return {
          Depå: depot.name,
        }
      })
    })

    return {
      columns,
      model,
      requiredRule,
      booleanTrueRequired,
      depots,
      add,
      remove,
      update,
      loading,
      resetFormModel,
      exportData,
    }
  },
})
